import React, { useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { Button } from "react-md";
import { useLocation } from "@reach/router";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { useToggle } from "@react-md/utils";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { Dialog, DialogContent } from "@react-md/dialog";
import CreateTeacherForm from "../TeachersSchools/CreateTeacherForm";
import CreateStudentForm from "../Students/CreateStudentForm";
import { evaluatePassword } from "../../utilities/utilities.js";
import ResetPasswordReminder from "../ResetPassword/ResetPasswordReminder.js";
import GoogleSSO from "../GoogleSSO";

import { TextField } from "@react-md/form";

import Logo from "../../assets/images/dm-Logo.png";

import "../../styles/components/Layout/navbar.scss";
import GoogleOrEmailSignUp from "../shared/GoogleOrEmailSignUp";
import {
  actuallyLogin,
  processPreloginFactor,
  handlePrelogin,
  getDeltaMathAPI,
} from "../../utilities/utilities";

const md5 = require("md5");

const stripSlashes = (path) => {
  return path.split("/").join("");
};

const required = "Fields are required";




function Navbar(props) {
  const { signIn, redirectUrl } = props;
  const [signInVisible, enableSignIn, disableSignIn] = useToggle(
    !!signIn
  );
  const [submitted, setSubmitted] = useState(false);
  const [chooseAccountTypeVisible, enableAccountType, disableAccountType] =
    useToggle(false);
  const [visibleCreateTeacher, enableCreateTeacher, disableCreateTeacher] =
    useToggle(false);
  const [visibleCreateStudent, enableCreateStudent, disableCreateStudent] =
    useToggle(false);
  const [visibleSignUpType, enableSignUpType, disableSignUpType] =
    useToggle(false);
  const [visiblePasswordReminder, showPasswordReminder, hidePasswordReminder] = useToggle(false)
  const [incognitoMode, setIncognitoMode] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [message, setMessage] = useState(null);
  const [passwordSecure, setPasswordSecure] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const {
    register,
    handleSubmit,
    errors,
    setError,
    reset,
    clearErrors,
    formState: { isSubmitting },
  } = useForm();

  const location = useLocation();

  const openSignInModal = async () => {
    enableSignIn();
  };

  // don't worry about prelogin here because we don't have to do slow hashing with this system
  const handleGoogleLogin = async (googleData) => {
    if (googleData.error === "idpiframe_initialization_failed") {
      props.disableOverlay();
      setIncognitoMode(true);
      return;
    }

    if (googleData.error && googleData.error === "popup_closed_by_user") {
      props.disableOverlay();
      return; // they know they closed it... no need to show message
    }

    if (!googleData.credential) {
      // how to show a message?
      props.disableOverlay();
      setError("submit", {
        type: "manual",
        message: "There was an error.",
      });
      return;
    }

    const res = await fetch(getDeltaMathAPI() + "/login_with_google", {
      method: "POST",
      body: JSON.stringify({ googleToken: googleData.credential }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();


    if (data.message) {
      // this is bad
      props.disableOverlay();
      setError("submit", {
        type: "manual",
        message: data.message,
      });
      return;
    }

    if (data.found === false) {
      props.disableOverlay();
      // open teacher/student account creation selection modal
      // setAccountData(data.ticket.payload);
      // enableAccountType();
      setError("submit", {
        type: "manual",
        message:
          "There is no existing account in the system with that Google email address. Please create an account.",
      });
      return;
    }
    if (redirectUrl) data.redirectUrl = redirectUrl;
    const now = new Date();
    data.googleSSOTimestamp = now.getTime();
    actuallyLogin(data, setError, clearErrors);
    setTimeout(() => props.disableOverlay(), 1000);
  };



  React.useEffect(() => {
    const currentPath = stripSlashes(location.pathname);
    if (currentPath === "teachers-schools") {
      document
        .getElementById("teachersLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "students") {
      document
        .getElementById("studentsLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "about") {
      document
        .getElementById("aboutLink")
        .firstChild.classList.toggle("active");
    }
    if (currentPath === "faqs") {
      document.getElementById("faqsLink").firstChild.classList.toggle("active");
    }
    if (currentPath === "contact") {
      document
        .getElementById("contactLink")
        .firstChild.classList.toggle("active");
    }
  }, [location.pathnmame]);

  React.useEffect(() => {
    if (signInVisible === false) {
      // resetStates();
      reset();
    }
  }, [signInVisible]);

  const handleToggle = () => {
    document.querySelector(".header__nav-links").classList.toggle("active");
    document.querySelector(".header__menuBar").classList.toggle("active");
    document.querySelector(".header__cross").classList.toggle("active");
  };

  let lastLoginAttempt;
  const onSubmit = async (data) => {
    try {
      const now = new Date().getTime();
      if (lastLoginAttempt && now - lastLoginAttempt < 1000) {
        return; // just don't allow repeated submissions... potential for abuse
      }
      lastLoginAttempt = now;

      let responseJSON = await handlePrelogin(data.usernameEmail.trim());

      let requestBody = {
        email: data.usernameEmail.trim(),
        password: md5("deltamath" + data.password),
        meets_criteria: passwordSecure,
        prelogin_factor: processPreloginFactor(responseJSON.number),
        prelogin_token: responseJSON.token,
      };

      if (
        typeof window !== "undefined" &&
        localStorage.getItem("customer_service_token")
      ) {
        // window type check needed because in build process there is no window defined
        requestBody.customer_service_token = localStorage.getItem(
          "customer_service_token"
        ); // see https://github.com/gatsbyjs/gatsby/issues/14480 for more information
      }

      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        credentials: "include",
      };

      // log in for real
      let response = await fetch(
        getDeltaMathAPI() + "/login",
        requestOptions
      ).catch((err) => {
        throw err;
      });
      if (!response.ok) {
        throw Error(response.status);
      }
      responseJSON = await response.json();
      if (responseJSON.message) {
        setError("submit", {
          type: "manual",
          message: responseJSON.message,
        });
        return;
      }
      if (redirectUrl) responseJSON.redirectUrl = redirectUrl;
      if(!passwordSecure) {
        /** Hide login modal, show update password option */
        setUserDetails(responseJSON);
        disableSignIn();
        showPasswordReminder();
      } else {
        actuallyLogin(responseJSON);
      }
    } catch (error) {
      // handle server errors
      if (error.message === "403") {
        setError("submit", {
          type: "manual",
          message:
            "Too many failed logins. Either change your password or wait one hour to try again.",
        });
      } else if (error.message === "417")  {
        let hostname;
        let prefix = "https://";
        let href;
        if (typeof window !== "undefined") {
          hostname = window.location.hostname;
          if (hostname === "localhost") {
            hostname += ":4200";
            prefix = "http://";
            href = prefix + hostname + "/home/";
          } else {
            href = prefix + hostname + "/home/";
          }
        }

        setError("submit", {
          type: "manual",
          message:
            <>
              {"This username is linked to a DeltaMath for Home account."}
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px"
              }}>
                <div><a href={href}>Sign in</a>{" "}to DeltaMath for Home</div>
                <div>or</div>
                <div><Link to="/students">Register</Link>{" "}for your teacher&apos;s class</div>
              </div>
            </>
        });
      }
      else {
        // if(error.message === '401') {
        setError("submit", {
          type: "manual",
          message: "Login failed. Please try again.",
        });
      }
    }

    setSubmitted(true);
  };

  const showSubmitError = (msg) => {
    return <div className="validation-alert msg-error">{msg.message}</div>;
  };

  const handleSignUpTypeSelection = (payload) => {
    const { selection, data, target } = payload;

    if (selection === "google") setAccountData(data);

    // open correct modal
    if (target === "teacher") enableCreateTeacher();
    else enableCreateStudent();
  };

  return (
    <div className="header__navbar">
      <div className="header__logo">
        <h1>
          <Link to="/">
            <img src={Logo} alt="DeltaMath" />
          </Link>
        </h1>
      </div>
      <ul className="header__nav-links">
        <li className="header__nav-link">
          <div
            className="header__cross header__controlButton"
            onClick={() => handleToggle()}
          >
            <div className="header__cross-top">
              {/* <div className="header__cross-top-spacer"></div> */}
              <h1 className="header__cross-top-logo">
                <Link to="/">
                  <img src={Logo} alt="DeltaMath" />
                </Link>
              </h1>
              <FaTimes className="header__cross-top-close" />
            </div>
          </div>
        </li>
        <li className="header__nav-link" id="teachersLink">
          <Link to="/teachers-schools">Teachers&#x200a;/&#x200a;Schools</Link>
        </li>
        <li className="header__nav-link" id="studentsLink">
          <Link to="/students">Students</Link>
        </li>
        <li
          className="header__nav-link"
          id="forhomeLink"
          style={{ paddingRight: 60 }}
        >
          <a href="/home" target="_blank">
            For Home
          </a>
          <div className="header__nav-link-home">
            <div>NEW</div>
          </div>
        </li>
        <li className="header__nav-link" id="aboutLink">
          <Link to="/about">About</Link>
        </li>
        <li className="header__nav-link" id="faqsLink">
          <Link to="/faqs">FAQs</Link>
        </li>
        <li className="header__nav-link" id="contactLink">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="header__nav-link">
          <div className="header__login-button-container-1">
            <Button
              id="login-form-dialog-toggle-1"
              className="header__login-button-1 login-form-dialog-toggle"
              theme="primary"
              themeType="contained"
              onClick={openSignInModal}
            >
              Login
            </Button>
          </div>
        </li>
      </ul>
      <div className="header__login-button-container-2">
        <Button
          id="login-form-dialog-toggle-2"
          className="header__login-button-2 login-form-dialog-toggle"
          theme="primary"
          themeType="contained"
          onClick={openSignInModal}
        >
          Login
        </Button>
      </div>
      <div
        className="header__menuBar header__controlButton"
        onClick={() => handleToggle()}
      >
        <FaBars />
      </div>
      {/* Please sign in modal */}
      <Dialog
        id="login-form-dialog"
        visible={signInVisible}
        onRequestClose={disableSignIn}
        aria-labelledby="dialog-title"
        className="loginFormDialog"
      >
        <span className="sr-only" id="dialogTitle">
          Please sign in dialog
        </span>
        <DialogContent className="login-form-content">
          <Card
            id="login-form-dialog-toggle-modal"
            className="modalTeamCard login-form-dialog-toggle"
            onClick={openSignInModal}
          >
            <CardHeader className="teamCard-text">
              <CardTitle className="teamCard-text-name">
                Please sign in
              </CardTitle>
            </CardHeader>
            <CardContent>
              {message ? (
                <div className="msg-error down-message">{message}</div>
              ) : (
                <></>
              )}
              <form
                className="signin-form"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
              >
                <div className="row">
                  <div className="column">
                    <TextField
                      id="usernameEmail"
                      name="usernameEmail"
                      // type="email"
                      placeholder="Username or email"
                      // value={usernameEmail}
                      onChange={() => clearErrors("submit")}
                      ref={register({ required })}
                      disabled={isSubmitting}
                    />

                    <TextField
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      // value={password}
                      onChange={(e) => {
                        clearErrors("submit");
                        setPasswordSecure(evaluatePassword(e.target.value));
                      }}
                      ref={register({ required })}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="row">
                  {errors.usernameEmail ? (
                    <div className="validation-alert msg-error ">
                      {errors.usernameEmail.message}
                    </div>
                  ) : errors.password ? (
                    <div className="validation-alert msg-error ">
                      {errors.password.message}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {errors && errors.submit && showSubmitError(errors.submit)}
                  {/* {submitted && showThankYou} */}
                  <div className="forget-password">
                    <Link to="/request-password-reset">forgot password?</Link>
                  </div>
                </div>
                <div className="row">
                  <Button
                    className="form-submit-btn"
                    themeType="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
                <>
                  {incognitoMode ? (
                    <div
                      className="validation-alert msg-info"
                      id="sign-in-with-google-error"
                    >
                      Your browser settings have disabled Sign in with Google.
                      See <Link to="/faqs#google-sso-error">this FAQ</Link> for
                      instructions to enable Sign in with Google.
                    </div>
                  ) : (
                    <></>
                  )}
                  <GoogleSSO
                    callBackFunction={handleGoogleLogin}
                    showOr={true}
                  />
                </>
              </form>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>

      {/* choose account type to create modal */}
      <Dialog
        id="choose-account-type-dialog"
        visible={chooseAccountTypeVisible}
        onRequestClose={disableAccountType}
        aria-labelledby="dialog-title"
        className="choose-account-type-dialog"
      >
        <span className="sr-only" id="dialog-title">
          Choose account type to create dialog
        </span>
        <DialogContent className="choose-account-type-form-content">
          <Card
            className="modalTeamCard"
            id="choose-account-type-form-dialog-toggle"
            onClick={openSignInModal}
          >
            <CardHeader className="teamCard-text">
              <CardTitle className="teamCard-text-name">
                DeltaMath account not found
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p>Choose an account type to create with your Google address:</p>
              <div className="type-button-wrapper">
                <Button
                  className="account-type-button"
                  theme="primary"
                  themeType="contained"
                  onClick={enableCreateTeacher}
                >
                  Teacher
                </Button>
                <Button
                  className="account-type-button"
                  theme="primary"
                  themeType="contained"
                  onClick={enableCreateStudent}
                >
                  Student
                </Button>
                <Button
                  className="account-type-button cancel-button"
                  theme="secondary"
                  themeType="contained"
                  onClick={disableAccountType}
                >
                  Cancel
                </Button>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      {visibleCreateTeacher ? (
        <CreateTeacherForm
          visibleCreate={visibleCreateTeacher}
          enableCreate={enableCreateTeacher}
          disableCreate={disableCreateTeacher}
          accountData={accountData}
        />
      ) : (
        <></>
      )}
      {visibleCreateStudent ? (
        <CreateStudentForm
          visible={visibleCreateStudent}
          enable={enableCreateStudent}
          disable={disableCreateStudent}
          accountData={accountData}
        />
      ) : (
        <></>
      )}
      {visibleSignUpType ? (
        <GoogleOrEmailSignUp
          visible={visibleSignUpType}
          enable={enableSignUpType}
          disable={disableSignUpType}
          selection={handleSignUpTypeSelection}
        />
      ) : (
        <></>
      )}
      {visiblePasswordReminder && Object.keys(userDetails).length ? (
        <ResetPasswordReminder
          visible={visiblePasswordReminder}
          enable={showPasswordReminder}
          disable={hidePasswordReminder}
          responseJSON={userDetails}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Navbar;
